import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, styled, Tooltip, Typography } from "@mui/material";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";

const PagerRoot = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
});

const Pager: FC<{ page: number; nbPages: number; onNext: () => void; onPrevious: () => void }> = ({
  page,
  nbPages,
  onNext,
  onPrevious,
}) => {
  const { t } = useTranslation("project");
  return (
    <PagerRoot>
      <Tooltip title={t("previousPage")} arrow>
        <IconButton onClick={onPrevious} disabled={page === 1}>
          <NavigateBefore />
        </IconButton>
      </Tooltip>
      <Typography style={{ marginLeft: 32, marginRight: 32 }}>{t("pageOf", { replace: { page, nbPages } })}</Typography>
      <Tooltip title={t("nextPage")} arrow>
        <IconButton onClick={onNext} disabled={page === nbPages}>
          <NavigateNext />
        </IconButton>
      </Tooltip>
    </PagerRoot>
  );
};

export default Pager;
