import { formatNumber, formatValue, getMetDomTom } from "../../data/dataConvertors";
import { Computation, Maybe, Operation, Sector, Form as ApiForm } from "../../data/generated/graphql";
import agriForms, { activeForms as agriActiveForms } from "./data/agriForms";
import barForms, { activeForms as barActiveForms } from "./data/barForms";
import batForms, { activeForms as batActiveForms } from "./data/batForms";
import indForms, { activeForms as indActiveForms } from "./data/indForms";
import resForms, { activeForms as resActiveForms } from "./data/resForms";
import traForms, { activeForms as traActiveForms } from "./data/traForms";
import { Form, getDisplayableFields } from "./formsTypes";

const defaultComputationDate = "2023-09-30T01:00:00.000Z";

export const isNotYetApplicableForm = (form?: Form): boolean => {
  const today = (): string => new Date().toISOString();
  return typeof form?.startDate !== "undefined" && form?.startDate > today() && typeof form?.history !== "undefined";
};

export const getFormsForSector = (sector?: Maybe<Sector>, zipCode?: string): Form[] => {
  const today = new Date().toISOString();
  const stillAliveFilter = (f: Form): boolean => !f.endDate || f.endDate < today;
  const metDomTom = zipCode ? getMetDomTom(zipCode) : 0;
  const metDomTomFormsFilter = (f: Form): boolean => !f.metDomTom || f.metDomTom === metDomTom;
  switch (sector) {
    case Sector.Ind:
      return indForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    case Sector.Agri:
      return agriForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    case Sector.Bat:
      return batForms
        .filter(metDomTomFormsFilter)
        .filter(stillAliveFilter)
        .concat(
          indForms.filter(
            (f) =>
              f.id === "IND-BA-112" ||
              f.id === "IND-UT-113" ||
              f.id === "IND-UT-115" ||
              f.id === "IND-UT-116" ||
              f.id === "IND-UT-117" ||
              f.id === "IND-UT-136",
          ),
        );
    case Sector.Tra:
      return traForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    case Sector.Res:
      return resForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    case Sector.Bar:
      return barForms.filter(metDomTomFormsFilter).filter(stillAliveFilter);
    default:
      return [];
  }
};

export const getActiveFormsId = (sector?: Maybe<Sector>): string[] => {
  switch (sector) {
    case Sector.Ind:
      return indActiveForms;
    case Sector.Agri:
      return agriActiveForms;
    case Sector.Bat:
      return batActiveForms;
    case Sector.Tra:
      return traActiveForms;
    case Sector.Res:
      return resActiveForms;
    case Sector.Bar:
      return barActiveForms;
    default:
      return [];
  }
};

export const formIsPreview = (form?: Form): boolean => {
  const today = new Date().toISOString();
  const isPreview = (form?.startDate && form.startDate > today && (form.history?.length || 0) === 0) || false;
  return isPreview;
};

export const getApplicableForm = (
  operation: Operation,
  sector: Sector,
  zipCode: string,
  originalOperation?: Operation,
): Form | undefined => {
  const form = getFormsForSector(sector, zipCode).find((f) => f.id === operation.formId);
  const computationDate =
    (typeof originalOperation !== "undefined"
      ? originalOperation?.computation?.computationDate
      : operation.computation?.computationDate) || defaultComputationDate;

  const isOperationOlderThanForm = typeof form?.startDate !== "undefined" && form.startDate > computationDate;
  if (isNotYetApplicableForm(form) || isOperationOlderThanForm) {
    const history = form?.history || [];
    // eslint-disable-next-line no-restricted-syntax
    for (const f of history) {
      if (!f.startDate || f.startDate < computationDate) {
        return f;
      }
    }
  }
  return form;
};

export const extractOperationsConfig = (
  sector: Maybe<Sector> | undefined,
  zipCode: string,
  operations: Operation[],
  prefix: string,
  success: () => void,
  failure: () => void,
  capaValue?: boolean,
  totalComputation?: Maybe<Computation>,
  cpePrefix?: string,
  bonusPrefix?: string,
  originalOperations?: Operation[],
): void => {
  const isCpeDefined = !Number.isNaN(parseFloat(totalComputation?.cpeBonus || ""));
  const lines: string[] = [];
  operations.forEach((o) => {
    const form = getApplicableForm(
      o,
      sector || Sector.Ind,
      zipCode,
      originalOperations?.find((originalOperation) => originalOperation.id === o.id) ||
        originalOperations?.find((originalOperation) => originalOperation.formId === o.formId),
    );
    const fields = getDisplayableFields(form?.fields || [], o.data);

    lines.push(
      `${prefix} ${o.id} - ${o.formId}${o.machineName ? ` (${o.machineName})` : ""}${
        capaValue
          ? ` : ${formatValue(o.computation?.capacity, true, 3, true)} MWhca${
              o.computation?.valuation === "0" ? "" : ` / ${formatValue(o.computation?.valuation, false, 2, true)} €`
            }`
          : ""
      }`,
    );
    fields.forEach((ff) => {
      lines.push(
        ` - ${
          ff.titles
            ? ff.titles.find((ft) => ft.fieldValue === o.data?.find((d) => d?.fieldId === ft.fieldId)?.value)?.title
            : ff.title
        } : ${
          ff.type === "number" || ff.type === "range"
            ? formatValue(o.data.find((d) => d?.fieldId === ff.id)?.value, true, 3)
            : ff.choices?.find((fc) => fc.value === o.data.find((d) => d?.fieldId === ff.id)?.value)?.name
        }`,
      );
    });
    if (o.bonusDetails && o.bonusDetails?.length > 0) {
      lines.push(`  ${o.bonusDetails?.length} ${bonusPrefix} :`);
      o.bonusDetails.forEach((b) => {
        lines.push(
          `   - ${b?.justification} ${b?.addition ? `+${b.addition}MWhca` : ""}${
            b?.multiplicator ? `x${b?.multiplicator}` : ""
          }`,
        );
      });
    }
  });
  if (
    capaValue &&
    totalComputation &&
    cpePrefix &&
    typeof totalComputation.cpeBonus !== "undefined" &&
    totalComputation.cpeBonus !== null &&
    isCpeDefined
  ) {
    lines.push(
      `${cpePrefix} : ${formatNumber(
        (parseFloat(totalComputation.capacity) / (1 + parseFloat(totalComputation.cpeBonus) / 100)) *
          (parseFloat(totalComputation.cpeBonus) / 100),
        3,
        true,
      )} MWhca / ${formatNumber(
        (parseFloat(totalComputation.valuation) / (1 + parseFloat(totalComputation.cpeBonus) / 100)) *
          (parseFloat(totalComputation.cpeBonus) / 100),
        2,
        true,
      )} €`,
    );
  }
  navigator.clipboard.writeText(lines.join("\n")).then(success, failure);
};

export const defaultSectorForms = (): Record<Sector, ApiForm[]> => {
  return {
    [Sector.Agri]: [],
    [Sector.Bar]: [],
    [Sector.Bat]: [],
    [Sector.Ind]: [],
    [Sector.Res]: [],
    [Sector.Tra]: [],
  };
};

export const getSectorFromFormId = (formId: string): Sector => formId.split("-")[0] as Sector;

export const formsToSectorForms = (forms: ApiForm[]): Record<Sector, ApiForm[]> => {
  const sectorFormsMap: Record<Sector, ApiForm[]> = defaultSectorForms();

  forms.forEach((form) => {
    const sector = getSectorFromFormId(form.id);
    if (!sectorFormsMap[sector]) {
      sectorFormsMap[sector] = [];
    }
    sectorFormsMap[sector].push(form);
  });
  return sectorFormsMap;
};
