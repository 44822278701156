import { ArrowBack } from "@mui/icons-material";
import { Button, IconButton, Modal, Paper, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Operation, OperationInput, Sector } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import WarningCard from "../../utils/WarningCard";
import FormFamily from "../form/FormFamily";
import FormFields from "../form/FormFields";
import { getActiveFormsId, getApplicableForm, getFormsForSector } from "../form/FormHelper";
import { Form, getDisplayableFields } from "../form/formsTypes";

interface FormEditModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: (newOperation: OperationInput) => void;
  sector: Sector;
  zipCode: string;
  operation?: OperationInput;
  operationIsEditing?: string;
  originalOperations?: Operation[];
}

const emptyOperation: OperationInput = {
  id: "",
  formId: "",
  data: [],
  isReplacement: false,
  isCpeScoped: false,
};

const FormEditModal: FC<FormEditModalProps> = ({
  open,
  onClose,
  onUpdate,
  operation,
  sector,
  zipCode,
  operationIsEditing,
  originalOperations,
}) => {
  const [family, setFamily] = useState<string | null>(null);
  const [newOperation, setNewOperation] = useState(operation || emptyOperation);
  const { t } = useTranslation("project");

  useEffect(() => {
    setNewOperation(operation || emptyOperation);
  }, [operation]);

  const onFormSelect = (form?: Form): void => {
    if (!form) return;
    setNewOperation({ ...emptyOperation, formId: form.id });
  };

  const forms = getFormsForSector(sector, zipCode);
  const selectedForm = getApplicableForm(
    newOperation,
    sector,
    zipCode,
    originalOperations?.find((originalOperation) => originalOperation.id === newOperation.id) ||
      originalOperations?.find((originalOperation) => originalOperation.formId === newOperation.formId),
  );
  const formChooser = family ? (
    <>
      <div className="row" style={{ alignItems: "flex-start" }}>
        <IconButton onClick={() => setFamily(null)} style={{ marginRight: 16 }}>
          <ArrowBack />
        </IconButton>
        <div>
          <Typography variant="h6">{t("chooseForm")}</Typography>
          <Typography variant="subtitle2" className="form-edit-title">
            {t("incomplete")}
          </Typography>
        </div>
      </div>
      <div className="form-edit-main">
        {getActiveFormsId(sector).map((fId) => {
          const f = forms.find((frm) => frm.id === fId);
          if (f?.type !== family) return undefined;
          return (
            <Button
              key={f?.id || ""}
              variant="outlined"
              color="primary"
              fullWidth
              className="form-edit-choose-button"
              onClick={() => onFormSelect(f)}>
              {fId} : {f?.name}
            </Button>
          );
        })}
      </div>
    </>
  ) : (
    <FormFamily sector={sector} setFamily={setFamily} setForm={onFormSelect} zipCode={zipCode} />
  );

  const validateButtonLabel = t("finalOperations.updateOperation");
  const isFormComplete: boolean =
    getDisplayableFields(selectedForm?.fields || [], newOperation.data).findIndex(
      (ff) => newOperation.data.findIndex((d) => d?.fieldId === ff.id && d?.value.length > 0) === -1,
    ) === -1;
  const isMinMaxOk: boolean =
    getDisplayableFields(selectedForm?.fields || [], newOperation.data).findIndex(
      (ff) =>
        newOperation.data.findIndex(
          (d) =>
            d?.fieldId === ff?.id &&
            (Number.parseFloat(d?.value || "0") < (ff?.minValue || 0) ||
              Number.parseFloat(d?.value || "0") > (ff?.maxValue || 1000000000000)),
        ) !== -1,
    ) === -1;

  const dataFiller = (
    <>
      <div className="row" style={{ gap: "16px" }}>
        {newOperation.id.length > 0 ? undefined : (
          <IconButton onClick={() => setNewOperation(emptyOperation)} style={{ marginRight: 16 }}>
            <ArrowBack />
          </IconButton>
        )}
        <div className="form-chosen-title">
          <Typography variant="h6">{selectedForm?.id}</Typography>
          <Typography variant="subtitle2">{selectedForm?.name}</Typography>
        </div>
        <GdButton label={t("seeForm")} onClick={() => window.open(selectedForm?.formUrl as string)} />
      </div>
      <div className="form-edit-main">
        <FormFields
          form={selectedForm}
          data={newOperation.data}
          onDataChange={(newData) => setNewOperation({ ...newOperation, data: newData })}
        />
        <div className="row">
          <Typography>{t("")}</Typography>
        </div>
        <WarningCard projectLabelKey="finalOperations.operationValuesWarning" className="margin-bottom margin-top" />
      </div>
      <GdButton
        label={validateButtonLabel}
        disabled={!isFormComplete || !isMinMaxOk}
        onClick={() => onUpdate(newOperation)}
        isLoading={operationIsEditing === operation?.id}
      />
    </>
  );
  return (
    <Modal
      open={open}
      onClose={() => {
        setNewOperation(emptyOperation);
        onClose();
      }}
      className="project-modal-root form-edit">
      <Paper className="project-modal-paper">{newOperation.formId.length === 0 ? formChooser : dataFiller}</Paper>
    </Modal>
  );
};

FormEditModal.defaultProps = {
  operation: undefined,
  operationIsEditing: undefined,
  originalOperations: undefined,
};

export default FormEditModal;
