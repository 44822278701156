import { createContext, FC, PropsWithChildren, useContext, useState } from "react";
import {
  GlobalRate,
  Obliged,
  Rate,
  Settings,
  useLaunchGetQuery,
  useObligedRateUpdateMutation,
  useSettingsGetAllQuery,
  useSettingsUpdateMutation,
} from "../generated/graphql";
import { ArticlesContext } from "./ArticlesContext";
import { ProjectsContext } from "./ProjectsContext";
import { UserContext } from "./UserContext";
import { UsersContext } from "./UsersContext";

export interface SettingsContextProps {
  launchGetData: () => Promise<boolean>;
  launching: boolean;
  settings: Settings;
  getSettings: () => Promise<boolean>;
  updateRate: (newRate: GlobalRate) => Promise<boolean>;
  updateRateHistory: (obligedId: string, rateHistory: Rate[]) => Promise<boolean>;
}

const emptySettings: Settings = {
  adminsEmails: [],
  rates: [],
  obliged: [],
  emmy: [],
  usage: [],
};

const initialContext: SettingsContextProps = {
  launchGetData: () => Promise.resolve(false),
  launching: false,
  settings: emptySettings,
  getSettings: () => Promise.resolve(false),
  updateRate: () => Promise.resolve(false),
  updateRateHistory: () => Promise.resolve(false),
};

export const SettingsContext = createContext<SettingsContextProps>(initialContext);

export const SettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [launching, setLaunching] = useState(true);
  const [settings, setSettings] = useState<Settings>(emptySettings);
  const [latestCheck, setLatestCheck] = useState(0);
  const { refetch } = useSettingsGetAllQuery({ skip: true });
  const [settingsUpdate] = useSettingsUpdateMutation();
  const { refetch: launchFetch } = useLaunchGetQuery({ skip: true });
  const [obligedRateUpdate] = useObligedRateUpdateMutation();
  const { replaceUserInfo } = useContext(UserContext);
  const { replaceProjects, setActions } = useContext(ProjectsContext);
  const { replaceUsers } = useContext(UsersContext);
  const { setArticles, setComments } = useContext(ArticlesContext);

  const setNewSettings = (newSettings: Settings): void => {
    const rates: GlobalRate[] = newSettings.rates ? [...newSettings.rates] : [];
    rates.sort((r1, r2) => {
      if ((r1?.period || "") > (r2?.period || "")) return -1;
      return 1;
    });
    const obliged: Obliged[] = newSettings.obliged ? [...newSettings.obliged] : [];
    obliged.sort((o1, o2) => {
      if (o1.active && !o2.active) return -1;
      if (!o1.active && o2.active) return 1;
      if (o1.name < o2.name) return -1;
      return 1;
    });
    setSettings({ ...newSettings, rates: rates.slice(0, 2), obliged });
  };

  const launchGetData = async (): Promise<boolean> => {
    setLaunching(true);
    const result = await launchFetch();
    if (result && result.data && result.data.userMe) {
      replaceUserInfo(result.data.userMe?.user || null);
      if (result.data.userGetAll) replaceUsers(result.data.userGetAll);
      if (result.data.projectGetAll) replaceProjects(result.data.projectGetAll);
      if (result.data.settingsGetAll) setNewSettings(result.data.settingsGetAll);
      if (result.data.projectGetAdminActions) setActions(result.data.projectGetAdminActions);
      if (result.data.articleGetAll) setArticles(result.data.articleGetAll);
      if (result.data.commentGetAll) {
        const finalComments = [...result.data.commentGetAll];
        finalComments.sort((c1, c2) => (c1.creation > c2.creation ? -1 : 1));
        setComments(finalComments);
      }
      setLatestCheck(new Date().getTime());
    }
    setLaunching(false);
    return result.data.userMe?.admin || false;
  };

  const getSettings = async (): Promise<boolean> => {
    // Check for new projects every 10 minutes
    if (settings.rates?.length !== 0 && latestCheck < new Date().getTime() - 10 * 60 * 1000) {
      const result = await refetch();
      if (result && result.data && result.data.settingsGetAll) {
        setNewSettings(result.data.settingsGetAll);
      }
      setLatestCheck(new Date().getTime());
      return false;
    }
    return true;
  };

  const updateRate = async (newRate: GlobalRate): Promise<boolean> => {
    const result = await settingsUpdate({ variables: { settings: { rate: newRate } } });
    if (result && result.data && result.data.settingsUpdate) {
      setNewSettings({ ...settings, rates: result.data.settingsUpdate.rates });
      return true;
    }
    return false;
  };

  const updateRateHistory = async (obligedId: string, rateHistory: Rate[]): Promise<boolean> => {
    const result = await obligedRateUpdate({ variables: { obligedId, rateHistory } });
    if (result && result.data && result.data.obligedRateUpdate) {
      setNewSettings({ ...settings, obliged: result.data.obligedRateUpdate.obliged });
      return true;
    }
    return false;
  };

  return (
    <SettingsContext.Provider
      value={{
        launchGetData,
        launching,
        settings,
        getSettings,
        updateRate,
        updateRateHistory,
      }}>
      {children}
    </SettingsContext.Provider>
  );
};
