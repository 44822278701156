import { MenuItem, Modal, Paper, Select, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { SettingsContext } from "../../data/contexts/SettingsContext";
import { formatCapacity } from "../../data/dataConvertors";
import { AdminActionType, DocumentType, Project } from "../../data/generated/graphql";
import GdAlert from "../../utils/GdAlert";
import GdButton from "../../utils/GdButton";
import SignerDetailsReadOnly from "../components/SignerDetailsReadOnly";

interface ConventionAskedProps {
  project: Project;
}

const ConventionAsked: FC<ConventionAskedProps> = ({ project }) => {
  const [isPreparing, setIsPreparing] = useState(false);
  const [isCreateConventionOpen, setIsCreateConventionOpen] = useState(false);
  const [isSignersOpen, setIsSignersOpen] = useState(false);
  const [refuseConventionOpen, setRefuseConventionOpen] = useState(false);
  const [refusing, setRefusing] = useState(false);
  const [refuseConventionReason, setRefuseConventionReason] = useState("");
  const [selectedObliged, setSelectedObliged] = useState<string | undefined>(project.obligedId || undefined);
  const [selectedType, setSelectedType] = useState<DocumentType>(project.convention?.type || DocumentType.Tripartite);
  const { settings } = useContext(SettingsContext);
  const { prepareConvention, markActionDone, refuseConvention } = useContext(ProjectsContext);
  const { t } = useTranslation("project");
  const { enqueueSnackbar } = useSnackbar();

  const checkConventionCreation = (): void => {
    // Check project dates are in the future!
    if (new Date(project.details?.endDate || 0).getTime() < new Date().getTime()) {
      enqueueSnackbar(t("projectIsOutdated"), { variant: "error" });
    } else {
      setIsCreateConventionOpen(true);
    }
  };

  const createConvention = async (): Promise<void> => {
    setIsPreparing(true);
    const result = await prepareConvention({ id: project.id, obligedId: selectedObliged }, selectedType);
    if (!result) enqueueSnackbar(t("prepareConventionFailed"), { variant: "error" });
    else markActionDone({ projectId: project.id, type: AdminActionType.CreateConvention });
    setIsPreparing(false);
    setIsCreateConventionOpen(false);
  };
  const refuseConventionCreation = async (): Promise<void> => {
    setRefuseConventionOpen(false);
    setRefusing(true);
    const result = await refuseConvention(project.id, refuseConventionReason);
    if (!result) enqueueSnackbar(t("refuseConventionFailed"), { variant: "error" });
    setRefusing(false);
  };
  const activeObliged = settings.obliged.filter((o) => o.active);

  return (
    <div>
      <div className="row space-between margin-bottom">
        <Typography variant="h6" className="margin-top margin-bottom">
          {t("projectAskedConvention")}
        </Typography>
        <GdButton label={t("seeSigners")} color="secondary" onClick={() => setIsSignersOpen(true)} />
        <Modal
          open={isCreateConventionOpen}
          onClose={() => setIsCreateConventionOpen(false)}
          className="project-modal-root">
          <Paper className="project-modal-paper" style={{ minHeight: "inherit" }}>
            <Typography className="margin-bottom">{t("chooseObliged")}</Typography>
            <Select
              value={selectedObliged}
              onChange={(e) => setSelectedObliged(e.target.value)}
              variant="outlined"
              fullWidth>
              {activeObliged.map((o) => (
                <MenuItem value={o.id} key={o.id}>
                  {o.name} ({t("capacityLeft")} {formatCapacity(o.capacityLeft || "")})
                </MenuItem>
              ))}
            </Select>
            <Typography className="margin-bottom margin-top">{t("chooseConventionType")}</Typography>
            <Select
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value as DocumentType)}
              variant="outlined"
              fullWidth>
              <MenuItem value={DocumentType.Tripartite}>{DocumentType.Tripartite}</MenuItem>
              <MenuItem value={DocumentType.Bipartite}>{DocumentType.Bipartite}</MenuItem>
            </Select>
            <GdButton
              label={t("createConvention")}
              onClick={createConvention}
              fullWidth
              className="margin-top"
              disabled={typeof selectedObliged === "undefined"}
              isLoading={isPreparing}
            />
          </Paper>
        </Modal>
        <Modal open={isSignersOpen} onClose={() => setIsSignersOpen(false)} className="project-modal-root">
          <Paper className="project-modal-paper" style={{ minHeight: "inherit" }}>
            <Typography variant="h5" className="margin-bottom text-center">
              {t("signers")}
            </Typography>
            <div className="row space-between">
              <SignerDetailsReadOnly signer={project.userSigner} title={t("userTwo")} />
              <div style={{ width: 32 }} />
              <SignerDetailsReadOnly signer={project.clientSigner || project.client} title={t("clientTwo")} />
            </div>
          </Paper>
        </Modal>
      </div>
      <div className="row space-between" style={{ margin: "0 128px" }}>
        <GdButton
          label={t("refuseConvention")}
          color="secondary"
          onClick={() => setRefuseConventionOpen(true)}
          isLoading={refusing}
        />
        <GdButton label={t("createConvention")} onClick={checkConventionCreation} />
      </div>
      <GdAlert
        open={refuseConventionOpen}
        onClose={() => setRefuseConventionOpen(false)}
        title={t("refuseConventionReasonTitle")}
        body={
          <TextField
            label={t("reason")}
            value={refuseConventionReason}
            className="margin-top"
            onChange={(e) => setRefuseConventionReason(e.target.value)}
            fullWidth
          />
        }
        okButtonClick={() => refuseConventionCreation()}
        cancelButton
      />
    </div>
  );
};

export default ConventionAsked;
