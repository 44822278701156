import { Tooltip, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pager from "../common/Pager";
import { ArticlesContext } from "../data/contexts/ArticlesContext";
import { UsersContext } from "../data/contexts/UsersContext";
import { dateConvertToString, formatValue } from "../data/dataConvertors";
import { ProjectLightFragment } from "../data/generated/graphql";
import GdDataGrid, { GdColumn, GdDataExtractor } from "../utils/GdDataGrid";
import StatusLabel from "../utils/StatusLabel";
import GanttView from "./ProjectsGanttView";
import ProjectsRevive from "./ProjectsRevive";

export enum Views {
  LIST = "LIST",
  GANTT = "GANTT",
  REVIVE = "REVIVE",
}

interface ProjectsSummaryProps {
  projects: ProjectLightFragment[];
  view?: Views;
  fromUser?: boolean;
  fromObliged?: boolean;
}

const ProjectsSummary: FC<ProjectsSummaryProps> = ({ projects, fromUser, fromObliged, view }) => {
  const { users } = useContext(UsersContext);
  const { comments } = useContext(ArticlesContext);
  const [currentPage, setCurrentPage] = useState(0);
  const { t } = useTranslation("project");
  const projectsOnPage = projects.slice(10 * currentPage, 10 * (currentPage + 1));
  const nbPages = projects.length > 10 ? Math.ceil(projects.length / 10) : 1;

  useEffect(() => {
    setCurrentPage(0);
  }, [projects]);

  const createCol = (key: string, dataExtractor?: GdDataExtractor, bold?: boolean): GdColumn => ({
    key,
    label: t(`projectCols.${key}`),
    dataExtractor,
    bold,
  });

  const projectColumns: GdColumn[] = [
    createCol("name", undefined, true),
    createCol("creation", (p) => (p.creation ? dateConvertToString(p.creation as string) : "")),
    createCol("user", (p) => users.find((u) => u.id === (p.userId as string))?.name || ""),
    createCol("capacity", (p) => formatValue((p as ProjectLightFragment).totalComputation?.capacity, true, 0, true)),
    createCol("valuation", (p) => formatValue((p as ProjectLightFragment).totalComputation?.valuation, true, 0)),
    createCol("detailedStatus", (p) => <StatusLabel status={(p as ProjectLightFragment).detailedStatus} />),
    createCol("latestComment", (p) => {
      const projectComments = comments.filter((c) => c.projectId === (p as ProjectLightFragment).id);
      return projectComments.length ? (
        <Tooltip
          title={
            <Typography className="ql-editor comment-tooltip">
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: projectComments[0].body }} />
            </Typography>
          }
          arrow>
          <Typography variant="body2">{dateConvertToString(projectComments[0].creation as string)}</Typography>
        </Tooltip>
      ) : (
        ""
      );
    }),
  ];

  const projectsForGantt = projects.filter((p) => typeof p.convention === "object");

  return (
    <>
      {view === Views.GANTT ? (
        <GanttView projects={projectsForGantt} />
      ) : view === Views.REVIVE ? (
        <ProjectsRevive projects={projects} />
      ) : (
        <div
          style={{
            height: "900px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <GdDataGrid
            columns={projectColumns.filter((c) => {
              if (fromUser && c.key === "user") return false;
              if (fromObliged && (c.key === "creation" || c.key === "valuation" || c.key === "status")) return false;
              return true;
            })}
            data={projectsOnPage}
            idExtractor={(p) => p.id as string}
            link="/projects/"
            hover
            nothingToShowLabel={t("noProjectForFilter")}
          />
          <Pager
            page={currentPage + 1}
            nbPages={nbPages}
            onNext={() => setCurrentPage(currentPage + 1)}
            onPrevious={() => setCurrentPage(currentPage - 1)}
          />
        </div>
      )}
    </>
  );
};

ProjectsSummary.defaultProps = { fromUser: false, fromObliged: false, view: Views.LIST };

export default ProjectsSummary;
