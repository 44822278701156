import { AppBar as MuiAppBar, Toolbar, IconButton, Typography, styled } from "@mui/material";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { drawerWidth } from "./AppDrawer";
import { ProjectsContext } from "../data/contexts/ProjectsContext";
import { UsersContext } from "../data/contexts/UsersContext";

const OurAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, about }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(about === "opened" && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface AppBarProps {
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  setAnchorEl: (el: Element) => void;
}

const AppBar: FC<AppBarProps> = ({ menuOpen, setMenuOpen, setAnchorEl }) => {
  const { t } = useTranslation("global");
  const { pathname } = useLocation();
  const { projects } = useContext(ProjectsContext);
  const { users } = useContext(UsersContext);
  const openMenu = (): void => setMenuOpen(true);
  const projectId = pathname.startsWith("/projects/") ? pathname.replace("/projects/", "").substring(0, 36) : null;
  const userId = pathname.startsWith("/users/") ? pathname.replace("/users/", "").substring(0, 36) : null;
  let forcedTitle = null;
  if (projectId && projectId !== "gantt" && projectId !== "revive") {
    forcedTitle = t("projectTwoName", {
      replace: { projectName: projects.find((p) => p.id === projectId)?.name || "" },
    });
  } else if (userId) {
    forcedTitle = t("userTwoName", {
      replace: { userName: users.find((u) => u.id === userId)?.name || t("notFound") },
    });
  }
  return (
    <OurAppBar position="static" about={menuOpen ? "opened" : "closed"}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={openMenu}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap style={{ flex: 1 }}>
          {forcedTitle || t([`titles.${pathname}`, "titles./"])}
        </Typography>
        <IconButton
          edge="end"
          aria-controls="auth-menu"
          aria-haspopup="true"
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
          color="inherit">
          <AccountCircle />
        </IconButton>
      </Toolbar>
    </OurAppBar>
  );
};

export default AppBar;
